.contenedor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80px; /* La altura del componente NavBar */
  padding: 20px;
  box-sizing: border-box;
  max-width: 100%; /* Ancho máximo del contenedor */
  width: 100%; /* Ancho del 100% del contenedor */
}

h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.scrollableTable {
  max-width: 100%;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  margin-bottom: 50px;
}

th,
td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}

th {
  background-color: #eee;
  white-space: nowrap;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
