.container {
  max-width: 100%;
  padding: 0 15px;
}

.prueba {
  width: 100%;
}

@media (max-width: 767px) {
  .prueba {
    width: 50%;
  }

  .container {
    max-width: 100%;
    padding: 0 15px;
  }
  
}
