.navbar {
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #333;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 99999;
  position: relative;
  }
  
  @media screen and (max-width: 768px) {
  .navbar {
  flex-direction: column;
  height: auto;
  overflow-y: hidden;
  }
  
  .navbar-collapse {
  width: 100%;
  }
  
  .navbar-nav {
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  }
  
  .nav-item {
  margin-top: 0.5rem;
  }
  }
  
  @media screen and (max-width: 480px) {
  .navbar {
    flex-direction: column;
    height: auto;
    overflow-y: hidden;
    width: 100%;
  }
  }