.fondo {
    display: flex;
  }
  
  .elementos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 80px; 
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%; 
  }
  