.elemento {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px; /* La altura del componente NavBar */
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%; /* Ancho máximo del contenedor */
    width: 100%; /* Ancho del 100% del contenedor */
}
.card {
    position: relative;
    z-index: 1;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .pagination li {
    display: inline-block;
  }
  
  .pagination a,
  .pagination span {
    display: block;
    padding: 0.5rem;
    margin-right: 0.25rem;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 0.25rem;
    text-decoration: none;
  }
  
  .pagination .active a,
  .pagination .active span {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }