.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  .table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  
  .table th {
    font-weight: bold;
    text-align: inherit;
    border-bottom: 2px solid #dee2e6;
    vertical-align: bottom;
    padding: 0.75rem;
  }
  
  .table td {
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    padding: 0.75rem;
  }
  
  .table tbody tr:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  
  .link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  @media (max-width: 767px) {
    .table {
      width: 20%;
      max-width: 20%;
      margin-bottom: 1rem;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
      justify-content: center;
    }

    .table tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
       width: 20%;
       justify-content: center;
      
    }
    
    .table th {
      font-weight: bold;
      text-align: inherit;
      border-bottom: 2px solid #dee2e6;
      vertical-align: bottom;
      padding: 0.3rem;
       width: 20%;
       justify-content: center;
      
    }
    
    .table td {
      vertical-align: top;
      justify-content: center;
      border-bottom: 1px solid #dee2e6;
      padding: 0.3rem;
       width: 20%;
      
    }
  }